import { fromJS } from "immutable";
import { ItemTypes, emptyMap, isLoadingMap, notLoadedMap } from "app/utils/constants";
import { arrayToPkMap } from "app/utils/immutableUtils";
import { ResourceBreedRecord, ResourceDetailedRecord, ResourceSimpleRecord } from "app/utils/records";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { actionTypes } from "./actions";

const initialState = fromJS({
  resources: {
    all: notLoadedMap,
    detailed: notLoadedMap,
  },
  resourceTemplates: {
    all: notLoadedMap,
    detailed: notLoadedMap,
  },
  breeds: {
    all: notLoadedMap,
    detailed: notLoadedMap,
  },
});

const breedFromJS = (breedJS) => {
  // TODO this is a temporary implementation, remove after upgrading to new API
  const breedPlannableResources = breedJS.plannable_resources || breedJS.resources || [];
  const breedTemplateResources = breedJS.template_resources || [];
  return ResourceBreedRecord(
    fromJS({
      ...breedJS,
      plannable_resources: breedPlannableResources,
      template_resources: breedTemplateResources,
    }),
  );
};

//
// Resources
//

function handleLoadSimpleResources(state, action) {
  if (action.status === "pending") {
    state = state.setIn(["resources", "all"], isLoadingMap);
  }
  if (action.status === "done") {
    const resourcesJS = action.payload.body.results;
    const resourcesMap = arrayToPkMap(resourcesJS, ItemTypes.resource).map(ResourceSimpleRecord);
    state = state.setIn(["resources", "all"], resourcesMap);
  }

  return state;
}

function handleClearSimpleResources(state, action) {
  if (action.status === "done") {
    state = state.setIn(["resources", "all"], notLoadedMap);
  }

  return state;
}

function handleLoadDetailedResource(state, action) {
  if (action.status === "pending") {
    state = state.setIn(["resources", "detailed"], isLoadingMap);
  }
  if (action.status === "done") {
    const resourceJS = action.payload.body;
    const resourceRecord = ResourceDetailedRecord(fromJS(resourceJS));
    state = state.setIn(["resources", "detailed"], resourceRecord);
  }
  return state;
}

function handleClearDetailedResource(state, action) {
  if (action.status === "done") {
    state = state.setIn(["resources", "detailed"], emptyMap);
  }

  return state;
}

function handleLoadSimpleResourceTemplates(state, action) {
  if (action.status === "pending") {
    state = state.setIn(["resourceTemplates", "all"], isLoadingMap);
  }
  if (action.status === "done") {
    const resourcesJS = action.payload.body.results;
    const resourcesMap = arrayToPkMap(resourcesJS, ItemTypes.resource).map(ResourceSimpleRecord);
    state = state.setIn(["resourceTemplates", "all"], resourcesMap);
  }

  return state;
}

function handleClearSimpleResourceTemplates(state, action) {
  if (action.status === "done") {
    state = state.setIn(["resourceTemplates", "all"], notLoadedMap);
  }

  return state;
}

function handleLoadDetailedResourceTemplate(state, action) {
  if (action.status === "pending") {
    state = state.setIn(["resourceTemplates", "detailed"], isLoadingMap);
  }
  if (action.status === "done") {
    const resourceJS = action.payload.body;
    const resourceRecord = ResourceDetailedRecord(fromJS(resourceJS));
    state = state.setIn(["resourceTemplates", "detailed"], resourceRecord);
  }
  return state;
}

function handleClearDetailedResourceTemplate(state, action) {
  if (action.status === "done") {
    state = state.setIn(["resourceTemplates", "detailed"], emptyMap);
  }

  return state;
}

//
// ResourceBreeds
//

function handleLoadSimpleBreeds(state, action) {
  if (action.status === "pending") {
    state = state.setIn(["breeds", "all"], isLoadingMap);
  }
  if (action.status === "done") {
    const breeds = action.payload.body.results.map(breedFromJS);
    const breedRecordsMap = arrayToPkMap(breeds, "breed");

    state = state.setIn(["breeds", "all"], breedRecordsMap);
  }
  return state;
}

function handleClearSimpleBreeds(state, action) {
  if (action.status === "done") {
    state = state.setIn(["breeds", "all"], notLoadedMap);
  }

  return state;
}

function handleLoadDetailedBreed(state, action) {
  if (action.status === "pending") {
    state = state.setIn(["breeds", "detailed"], isLoadingMap);
  }

  if (action.status === "done") {
    const breedJS = action.payload.body;
    const breed = breedFromJS(breedJS);

    state = state.setIn(["breeds", "detailed"], breed);
  }

  return state;
}

function handleClearDetailedBreed(state, action) {
  if (action.status === "done") {
    state = state.setIn(["breeds", "detailed"], notLoadedMap);
  }

  return state;
}

/***
 * Resources reducer
 * @param state
 * @param action
 */
export default function (state = initialState, action) {
  return reduceWithHandlers(state, action, {
    // Resources
    [actionTypes.LOAD_SIMPLE_RESOURCES]: handleLoadSimpleResources,
    [actionTypes.LOAD_DETAILED_RESOURCE]: handleLoadDetailedResource,
    [actionTypes.CLEAR_SIMPLE_RESOURCES]: handleClearSimpleResources,
    [actionTypes.CLEAR_DETAILED_RESOURCE]: handleClearDetailedResource,

    // ResourceTemplates
    [actionTypes.LOAD_SIMPLE_RESOURCE_TEMPLATES]: handleLoadSimpleResourceTemplates,
    [actionTypes.LOAD_DETAILED_RESOURCE_TEMPLATE]: handleLoadDetailedResourceTemplate,
    [actionTypes.CLEAR_SIMPLE_RESOURCE_TEMPLATES]: handleClearSimpleResourceTemplates,
    [actionTypes.CLEAR_DETAILED_RESOURCE_TEMPLATE]: handleClearDetailedResourceTemplate,

    // ResourceBreeds
    [actionTypes.LOAD_SIMPLE_BREEDS]: handleLoadSimpleBreeds,
    [actionTypes.CLEAR_SIMPLE_BREEDS]: handleClearSimpleBreeds,
    [actionTypes.LOAD_DETAILED_BREED]: handleLoadDetailedBreed,
    [actionTypes.CLEAR_DETAILED_BREED]: handleClearDetailedBreed,
  });
}
