//
// Action creators for resources app
//
/**
 * @typedef Action {type: string, payload: *}
 */
import { getEmptyPromise } from "app/utils/dal";
import ResourcesDal from "./dal";

export const actionTypes = {
  LOAD_SIMPLE_RESOURCES: "resources/LOAD_SIMPLE_RESOURCES",
  LOAD_DETAILED_RESOURCE: "resources/LOAD_DETAILED_RESOURCE",
  CLEAR_SIMPLE_RESOURCES: "resources/CLEAR_SIMPLE_RESOURCES",
  CLEAR_DETAILED_RESOURCE: "resources/CLEAR_DETAILED_RESOURCE",

  CLONE_RESOURCE: "resources/CLONE_RESOURCE",
  CREATE_RESOURCE: "resources/CREATE_RESOURCE",
  CREATE_RESOURCE_FROM_TEMPLATE: "resources/CREATE_RESOURCE_FROM_TEMPLATE",
  CREATE_TEMPLATE_FROM_RESOURCE: "resources/CREATE_TEMPLATE_FROM_RESOURCE",
  UPDATE_RESOURCE: "resources/UPDATE_RESOURCE",
  UPDATE_RESOURCE_SKILLS: "resources/UPDATE_RESOURCE_SKILLS",
  DELETE_RESOURCE: "resources/DELETE_RESOURCE",

  // TEMPLATES
  LOAD_SIMPLE_RESOURCE_TEMPLATES: "resources/LOAD_SIMPLE_RESOURCE_TEMPLATES",
  LOAD_DETAILED_RESOURCE_TEMPLATE: "resources/LOAD_DETAILED_RESOURCE_TEMPLATE",
  CLEAR_SIMPLE_RESOURCE_TEMPLATES: "resources/CLEAR_SIMPLE_RESOURCE_TEMPLATES",
  CLEAR_DETAILED_RESOURCE_TEMPLATE: "resources/CLEAR_DETAILED_RESOURCE_TEMPLATE",

  CLONE_RESOURCE_TEMPLATE: "resources/CLONE_RESOURCE_TEMPLATE",
  CREATE_RESOURCE_TEMPLATE: "resources/CREATE_RESOURCE_TEMPLATE",
  UPDATE_RESOURCE_TEMPLATE: "resources/UPDATE_RESOURCE_TEMPLATE",
  UPDATE_RESOURCE_TEMPLATE_SKILLS: "resources/UPDATE_RESOURCE_TEMPLATE_SKILLS",
  DELETE_RESOURCE_TEMPLATE: "resources/DELETE_RESOURCE_TEMPLATE",

  // BREEDS
  LOAD_SIMPLE_BREEDS: "resources/LOAD_SIMPLE_BREEDS",
  CLEAR_SIMPLE_BREEDS: "resources/CLEAR_SIMPLE_BREEDS",
  LOAD_DETAILED_BREED: "resources/LOAD_DETAILED_BREED",
  CLEAR_DETAILED_BREED: "resources/CLEAR_DETAILED_BREED",
  CREATE_BREED: "resources/CREATE_BREED",
  UPDATE_BREED: "resources/UPDATE_BREED",
  UPDATE_BREED_RESOURCES: "resources/UPDATE_BREED_RESOURCES",
  UPDATE_BREED_RESOURCE_TEMPLATES: "resources/UPDATE_BREED_RESOURCE_TEMPLATES",
  DELETE_BREED: "resources/DELETE_BREED",
};

const resourcesActionCreators = {
  /***
   * Load all simple resources
   * @returns {Action}
   */
  loadSimpleResources() {
    const dal = new ResourcesDal();
    const actionPromise = dal.getSimpleResources();
    return {
      type: actionTypes.LOAD_SIMPLE_RESOURCES,
      payload: actionPromise,
    };
  },

  clearSimpleResources() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_SIMPLE_RESOURCES,
      payload: actionPromise,
    };
  },

  /***
   * Loads detailed resource
   * @param resourcePk {number}
   * @returns {Action}
   */
  loadDetailedResource(resourcePk) {
    const dal = new ResourcesDal();
    const actionPromise = dal.getDetailedResource(resourcePk);
    return {
      type: actionTypes.LOAD_DETAILED_RESOURCE,
      payload: actionPromise,
    };
  },

  clearDetailedResource() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_DETAILED_RESOURCE,
      payload: actionPromise,
    };
  },

  /***
   * Clones resource
   * @param pk
   * @returns {Action}
   */
  cloneResource(pk) {
    const dal = new ResourcesDal();
    const actionPromise = dal.cloneResource(pk);
    return {
      type: actionTypes.CLONE_RESOURCE,
      payload: actionPromise,
    };
  },

  /***
   * Creates resources
   * @param resource
   * @returns {Action}
   */
  createResource(resource) {
    const dal = new ResourcesDal();
    const actionPromise = dal.createResource(resource);
    return {
      type: actionTypes.CREATE_RESOURCE,
      payload: actionPromise,
    };
  },

  createResourceFromTemplate(resource, resourceTemplatePk) {
    const dal = new ResourcesDal();
    const actionPromise = dal.createResourceFromTemplate(resource, resourceTemplatePk);
    return {
      type: actionTypes.CREATE_RESOURCE_FROM_TEMPLATE,
      payload: actionPromise,
    };
  },

  createTemplateFromResource(resourcePk) {
    const dal = new ResourcesDal();
    const actionPromise = dal.createTemplateFromResource(resourcePk);
    return {
      type: actionTypes.CREATE_TEMPLATE_FROM_RESOURCE,
      payload: actionPromise,
    };
  },

  /***
   * Updates resource
   * @param resource
   * @returns {Action}
   */
  updateResource(resource) {
    const dal = new ResourcesDal();
    const actionPromise = dal.putResource(resource);
    return {
      type: actionTypes.UPDATE_RESOURCE,
      payload: actionPromise,
    };
  },

  /***
   * Update skills of Resource
   * @param resourcePk {number}
   * @param skillPks {[number]}
   * @returns {Action}
   */
  updateResourceSkills(resourcePk, skillPks) {
    const dal = new ResourcesDal();
    const actionPromise = dal.patchResourceSkills(resourcePk, skillPks);
    return {
      type: actionTypes.UPDATE_RESOURCE_SKILLS,
      payload: actionPromise,
    };
  },

  /***
   * Deletes resource
   * @param resourcePk {number}
   * @returns {Action}
   */
  deleteResource(resourcePk) {
    const dal = new ResourcesDal();
    const actionPromise = dal.deleteResource(resourcePk);
    return {
      type: actionTypes.DELETE_RESOURCE,
      payload: actionPromise,
    };
  },

  //
  // TEMPLATES
  //

  /***
   * Load all simple resource templates
   * @returns {Action}
   * */
  loadSimpleResourceTemplates() {
    const dal = new ResourcesDal();
    const actionPromise = dal.getSimpleResourceTemplates();
    return {
      type: actionTypes.LOAD_SIMPLE_RESOURCE_TEMPLATES,
      payload: actionPromise,
    };
  },

  clearSimpleResourceTemplates() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_SIMPLE_RESOURCE_TEMPLATES,
      payload: actionPromise,
    };
  },

  loadDetailedResourceTemplate(resourceTemplatePk) {
    const dal = new ResourcesDal();
    const actionPromise = dal.getDetailedResource(resourceTemplatePk);
    return {
      type: actionTypes.LOAD_DETAILED_RESOURCE_TEMPLATE,
      payload: actionPromise,
    };
  },

  clearDetailedResourceTemplate() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_DETAILED_RESOURCE_TEMPLATE,
      payload: actionPromise,
    };
  },

  deleteResourceTemplate(resourceTemplatePk) {
    const dal = new ResourcesDal();
    const actionPromise = dal.deleteResourceTemplate(resourceTemplatePk);
    return {
      type: actionTypes.DELETE_RESOURCE_TEMPLATE,
      payload: actionPromise,
    };
  },

  //
  // BREEDS
  //

  loadSimpleBreeds() {
    const dal = new ResourcesDal();
    const actionPromise = dal.getSimpleBreeds();
    return {
      type: actionTypes.LOAD_SIMPLE_BREEDS,
      payload: actionPromise,
    };
  },

  clearSimpleBreeds() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_SIMPLE_BREEDS,
      payload: actionPromise,
    };
  },

  loadDetailedBreed(breedPk) {
    const dal = new ResourcesDal();
    const actionPromise = dal.getDetailedBreed(breedPk);
    return {
      type: actionTypes.LOAD_DETAILED_BREED,
      payload: actionPromise,
    };
  },

  clearDetailedBreed() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_DETAILED_BREED,
      payload: actionPromise,
    };
  },

  createBreed(breed) {
    const dal = new ResourcesDal();
    const actionPromise = dal.createBreed(breed);
    return {
      type: actionTypes.CREATE_BREED,
      payload: actionPromise,
    };
  },

  updateBreed(breed) {
    const dal = new ResourcesDal();
    const actionPromise = dal.putBreed(breed);
    return {
      type: actionTypes.UPDATE_BREED,
      payload: actionPromise,
    };
  },

  updateBreedResources(breedPk, resourcePks) {
    const dal = new ResourcesDal();
    const actionPromise = dal.updateBreedResources(breedPk, resourcePks);
    return {
      type: actionTypes.UPDATE_BREED_RESOURCES,
      payload: actionPromise,
    };
  },

  updateBreedResourceTemplates(breedPk, resourceTemplatePks) {
    const dal = new ResourcesDal();
    const actionPromise = dal.updateBreedResourceTemplates(breedPk, resourceTemplatePks);
    return {
      type: actionTypes.UPDATE_BREED_RESOURCE_TEMPLATES,
      payload: actionPromise,
    };
  },

  deleteBreed(breedPk) {
    const dal = new ResourcesDal();
    const actionPromise = dal.deleteBreed(breedPk);
    return {
      type: actionTypes.DELETE_BREED,
      payload: actionPromise,
    };
  },
};

export default resourcesActionCreators;
