import { Location } from "history";
import { ComponentType, useMemo } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useAppTenantUserLinkContext } from "app/core/providers/AppTenantUserLinkProvider";
import NotFound from "app/common/components/NotFound";

type Role = string;
type Roles = Role[] | null;

const removeDoubleSlashes = (s: string) =>
  s
    .replace(/\/+/g, "/") // replace consecutive slashes with a single slash
    .replace(/\/+$/, ""); // remove trailing slashes

export const nestedPath = (url: string, path: string) => removeDoubleSlashes(`${url}/${path}`);

interface RouteProps {
  path?: string;
  component?: ComponentType<unknown>;
  exact?: boolean;
  nested?: boolean;
  redirect?: string;
  roles?: Roles;
  [key: string]: unknown; // other route props
}

interface RenderSwitchRouteProps extends RouteProps {
  userRoles: Roles;
  switchUrl: string;
  switchRouteProps: object;
  switchNotFoundComponent: ComponentType;
}

const renderSwitchRoute = ({
  userRoles = null,
  switchUrl,
  switchRouteProps,
  switchNotFoundComponent = NotFound,
  // Route props TODO don't allow ...otherRouteProps to be passed like that
  component,
  exact,
  nested,
  redirect,
  path = "",
  roles = null,
  ...otherRouteProps
}: RenderSwitchRouteProps) => {
  const isNestedPath = nested !== false;
  const composedPath = isNestedPath ? nestedPath(switchUrl, path) : path;

  const userNotAllRoles = !allRolesMatched(roles, userRoles);
  if (userNotAllRoles) {
    // If user is not allowed to access this route, we return an exact route with the switchNotFoundComponent.
    return <Route exact={exact} key={composedPath} path={composedPath} component={switchNotFoundComponent} />;
  }

  if (redirect) {
    const redirectTo = isNestedPath ? nestedPath(switchUrl, redirect) : redirect;
    return <Redirect exact={exact} key={composedPath} path={composedPath} to={redirectTo} />;
  }

  const RenderComponent = component as ComponentType;
  const Renderer = () => <RenderComponent {...switchRouteProps} {...otherRouteProps} />;

  return <Route exact={exact} key={composedPath} path={composedPath} render={Renderer} />;
};

interface RenderSwitchRoutesProps {
  indexRoute?: RouteProps | null;
  routes: RouteProps[];
  switchRouteProps: RouteProps;
  switchUrl: string;
  switchNotFoundComponent: ComponentType;
  userRoles: Roles;
}

const renderSwitchRoutes = ({
  indexRoute,
  routes,
  switchRouteProps,
  switchUrl,
  switchNotFoundComponent,
  userRoles,
}: RenderSwitchRoutesProps) =>
  [
    ...routes,
    indexRoute && {
      exact: true,
      path: "",
      key: "indexRoute",
      ...indexRoute,
    },
  ]
    .filter(Boolean)
    .map((switchRoute) =>
      renderSwitchRoute({
        userRoles,
        switchRouteProps,
        switchUrl,
        switchNotFoundComponent,
        ...switchRoute,
      }),
    );

const RedirectTrailingSlashRenderer = ({ location }: { location: Location }) => {
  const urlWithSlashWithoutQuery = location.pathname;
  const query = location.search;
  const urlWithoutTrailingSlash = urlWithSlashWithoutQuery.slice(0, -1) + query;
  return <Redirect to={`${urlWithoutTrailingSlash}`} />;
};

const getNotFoundRoute = (component: ComponentType = NotFound) => (
  <Route path="*" key="notFound" component={component} />
);

const allRolesMatched = (rolesToMatch: Roles, userRoles: Roles) =>
  !rolesToMatch || !rolesToMatch.length || (userRoles && rolesToMatch.every((role) => userRoles.indexOf(role) !== -1));

export const useCurrentUserHasAllRoles = (roles: Roles) => {
  const { tenantUserRoles } = useAppTenantUserLinkContext();
  return useMemo(() => allRolesMatched(roles, tenantUserRoles), [roles, tenantUserRoles]);
};

interface CustomSwitchProps {
  routes?: RouteProps[];
  routeRoles?: Roles;
  routeProps?: RouteProps;
  indexRoute?: RouteProps | null;
  notFoundComponent?: ComponentType;
}

export const CustomSwitch = ({
  routes = [],
  routeProps = {},
  routeRoles = null,
  indexRoute = null,
  notFoundComponent = NotFound,
}: CustomSwitchProps) => {
  const { url } = useRouteMatch();
  const { tenantUserRoles } = useAppTenantUserLinkContext();

  // Always redirect trailing slashes
  const redirectTrailingSlashRoute = <Route path="/*/" exact strict render={RedirectTrailingSlashRenderer} />;

  // Always render the notFoundRoute if notFoundComponent is provided
  const notFoundRoute = notFoundComponent ? getNotFoundRoute(notFoundComponent) : null;

  // The switchRoutes will be rendered only if the user has all the roles required by the routeRoles
  const switchRoutes = allRolesMatched(routeRoles, tenantUserRoles)
    ? renderSwitchRoutes({
        indexRoute,
        routes,
        switchNotFoundComponent: notFoundComponent,
        switchRouteProps: routeProps,
        switchUrl: url,
        userRoles: tenantUserRoles,
      })
    : [];

  // NOTE that all children of Switch MUST be Route (not components that wrap Route!)
  return (
    <Switch>
      {redirectTrailingSlashRoute}
      {switchRoutes}
      {notFoundRoute}
    </Switch>
  );
};
