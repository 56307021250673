import { Reducer } from "redux";
import { emptyMap } from "app/utils/constants";
import { State } from "app/utils/redux/reducerHandlers";
import { Action } from "app/utils/redux/standardActions";

//
// Combines immutable reducers
//
// Equivalent of redux's combineReducers, but working with reducers that take and return
// Immutable objects.
//
// Args:
//  reducers: { key: reducer } object: associates a reducer function to keys in state
//             each reducer will receive that subtree and be responsible for regenerating it
//  initialState: (optional) immutable object containing initial state
//
type Reducers = { [key: string]: Reducer<State, Action> };

function combineImmutableReducers(reducers: Reducers, initialState = emptyMap) {
  function combinedReducer(state: State, action: Action) {
    state = state || initialState;

    // Call every reducer in reducers, alphabetically
    Object.keys(reducers)
      .sort()
      .forEach((key) => {
        const reducer = reducers[key];
        const oldReducerState = state.get(key) as State;
        const newReducerState = reducer(oldReducerState, action);
        state = state.set(key, newReducerState);
      });

    return state;
  }

  return combinedReducer;
}

export default combineImmutableReducers;
