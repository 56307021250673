// Initialise sentry for error reporting
import * as Sentry from "@sentry/browser";
import { sentryDSN, sentryEnvironment } from "app/process";

export const initialiseSentryErrorReporting = () => {
  if (sentryDSN) {
    console.log(`[Sentry] Initialising ${sentryDSN} for environment ${sentryEnvironment}`);
    Sentry.init({ dsn: sentryDSN, environment: sentryEnvironment });
  } else {
    console.log("[Sentry] Improperly configured, can't initialise...");
  }
};

export const openSentryReportDialog = () => Sentry.showReportDialog();

export default Sentry;
