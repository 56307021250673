import { fromJS } from "immutable";
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppTenantContext } from "app/core/providers/AppTenantDetectionProvider";
import InternalDal from "app/utils/InternalDal";
import { isMapNotReady, notLoadedMap } from "app/utils/constants";
import {
  getUserIsYouPlanStaff,
  getUserName,
  getUserRoleIsSpectator,
  getUserTenantSchemaName,
} from "app/features/users/selectors";

const disabledTenantUserLink = true;

// TODO in the future we could have roles like: spectator, planner, staff, admin
export const plannerRoles = ["planner"];

interface GetTenantUserLinkResponse {
  body: {
    results: {
      user: {
        username: string;
      };
      tenant: {
        schema_name: string;
      };
      spectator: boolean;
    }[];
  };
}

interface AppTenantUserLinkContextValue {
  tenantUserLink: Map<string, unknown>;
  isWaitingForTenantUserLink: boolean;

  isYouPlanStaff: boolean;
  isTenantUserPlanner: boolean;
  isTenantUserSpectator: boolean;
  tenantUserRoles: string[] | null;
}

const defaultTenantUserLinkContextValue = {
  tenantUserLink: notLoadedMap,
  isWaitingForTenantUserLink: false,

  isYouPlanStaff: false,
  isTenantUserPlanner: false,
  isTenantUserSpectator: false,
  tenantUserRoles: null,
};

const AppTenantUserLinkContext = createContext<AppTenantUserLinkContextValue>(defaultTenantUserLinkContextValue);

const useTenantUserLinkIfNotYouPlanStaff = () => {
  // TODO
  //  - the roles should come from tenantUserLink, if isYouPlanStaff then we should put true, true and ["planner"].

  const { tenantIdentityProvider } = useAppTenantContext();
  const { tenantSchemaName } = tenantIdentityProvider;
  const [tenantUserLink, setTenantUserLink] = useState(notLoadedMap);

  const isYouPlanStaff = useSelector(getUserIsYouPlanStaff);
  const isTenantUserSpectator = useSelector(getUserRoleIsSpectator);
  const isTenantUserPlanner = !isTenantUserSpectator;
  const tenantUserRoles = isTenantUserPlanner ? plannerRoles : null;
  const userName = useSelector(getUserName);
  const userTenantSchemaName = useSelector(getUserTenantSchemaName);

  const isNotYouPlanStaff = !isYouPlanStaff;
  const isNotReady = isMapNotReady(tenantUserLink);
  const isWaitingForLink = isNotReady && isNotYouPlanStaff && !disabledTenantUserLink;
  const isNotWaitingForLink = !isWaitingForLink;

  // TODO
  //  - make this work
  //  - determine proper URL to retrieve tenant-user-link from!
  const loadTenantUserLink = useCallback(() => {
    if (disabledTenantUserLink) return;

    const dal = new InternalDal();
    const url = `${dal.baseTenantUrl}/tenant-user-links`;
    // const url = `${dal.baseTenantUrl}/tenant-user-links?schema_name=${tenantSchemaName}&user=${userPk}`;

    dal.authHeader = undefined;
    dal.getter<GetTenantUserLinkResponse>(url, "getTenantUserLink").then((response) => {
      const link = response.body.results[0];
      setTenantUserLink(fromJS(link));
    });
  }, []);

  const wrongTenantUserLink = !(
    isNotWaitingForLink &&
    tenantUserLink.getIn(["user", "username"]) === userName &&
    tenantUserLink.getIn(["tenant", "schema_name"]) === tenantSchemaName
  );
  const shouldLoadTenantUserLink =
    tenantSchemaName && // Detected
    userTenantSchemaName && // Authorized
    isNotYouPlanStaff &&
    wrongTenantUserLink;

  useEffect(() => {
    if (shouldLoadTenantUserLink) {
      loadTenantUserLink();
    }
  }, [shouldLoadTenantUserLink, loadTenantUserLink]);

  const isLinkSpectator = tenantUserLink.get("spectator");
  const isTenantUserPlannerFromLink = isYouPlanStaff || (isNotWaitingForLink && !isLinkSpectator);
  const isTenantUserSpectatorFromLink = isNotWaitingForLink && isLinkSpectator;
  const tenantUserRolesFromLink = isTenantUserPlannerFromLink ? plannerRoles : null;

  return {
    tenantUserLink,
    isWaitingForTenantUserLink: isWaitingForLink,

    isYouPlanStaff,
    isTenantUserPlanner: disabledTenantUserLink ? isTenantUserPlanner : isTenantUserPlannerFromLink,
    isTenantUserSpectator: disabledTenantUserLink ? isTenantUserSpectator : isTenantUserSpectatorFromLink,
    tenantUserRoles: disabledTenantUserLink ? tenantUserRoles : tenantUserRolesFromLink,
  };
};

const AppTenantUserLinkProvider = ({ children }: { children: ReactNode }) => (
  <AppTenantUserLinkContext.Provider value={useTenantUserLinkIfNotYouPlanStaff()}>
    {children}
  </AppTenantUserLinkContext.Provider>
);

export const useAppTenantUserLinkContext = () => useContext(AppTenantUserLinkContext);

export default AppTenantUserLinkProvider;
