import { EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiGlobalToastList, EuiTitle } from "@elastic/eui";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import restrictStringLength from "app/utils/restrictStringLength";
import { useAppActions } from "app/actions";
import { getAppMessages } from "app/features/appMessages/selectors";
import { isNotProduction } from "app/process";

interface HTMLFlyoutProps {
  title: string;
  htmlStr: string;
  onClose: () => void;
}

type Message = unknown;
type Toast = unknown;

const toastLifeTimeMs = 6000;
const htmlStartStr = "<!DOCTYPE html>";

const HTMLIFrame = ({ htmlStr }: { htmlStr: string }) => <iframe height={800} width={"100%"} srcDoc={htmlStr} />;

const HTMLFlyout = ({ title, htmlStr, onClose }: HTMLFlyoutProps) => (
  <EuiFlyout ownFocus onClose={onClose} size="l">
    <EuiFlyoutHeader hasBorder>
      <EuiTitle size="s">
        <h2>{title}</h2>
      </EuiTitle>
    </EuiFlyoutHeader>
    <EuiFlyoutBody
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <HTMLIFrame htmlStr={htmlStr} />
    </EuiFlyoutBody>
  </EuiFlyout>
);

const useAppToasts = () => {
  const actions = useAppActions();
  const messages = useSelector(getAppMessages);

  const [htmlStr, setHtmlStr] = useState(null);
  const clearHtmlStr = useCallback(() => setHtmlStr(null), []);

  // Map messages to toasts.
  // We only display errors currently.
  const toasts = useMemo(
    () =>
      messages
        // @ts-expect-error TODO fix Message type
        .filter((m: Message) => m.get("type") === "error")
        .map((m: Message) => {
          // @ts-expect-error TODO fix Message type
          const msgLong = m.get("message");
          const msg = restrictStringLength(msgLong, 640);

          const wrapWithHtmlStrLink = isNotProduction && msg.startsWith(htmlStartStr);
          const text = wrapWithHtmlStrLink ? (
            // eslint-disable-next-line formatjs/no-literal-string-in-jsx
            <a onClick={() => setHtmlStr(msgLong)}>Error message is HTML, click to open flyout.</a>
          ) : (
            <p>{msg}</p>
          );

          return {
            // @ts-expect-error TODO fix Message type
            id: m.get("id"),
            title: "Oops, there was an error",
            color: "danger",
            iconType: "alert",
            text,
          };
        })
        .toArray(),
    [messages],
  );
  // @ts-expect-error TODO fix Toast type
  const dismissToast = useCallback((toast: Toast) => actions.appMessages.dismissMessage(toast.id), [actions]);
  const dismissAllToasts = useCallback(() => {
    actions.appMessages.dismissAllMessages();
  }, [actions]);

  return {
    toasts,
    dismissToast,
    dismissAllToasts,
    htmlStr,
    clearHtmlStr,
  };
};

const AppToasts = () => {
  const { toasts, dismissToast, htmlStr, clearHtmlStr } = useAppToasts();

  return (
    <>
      <EuiGlobalToastList toasts={toasts} dismissToast={dismissToast} toastLifeTimeMs={toastLifeTimeMs} />
      {htmlStr && <HTMLFlyout title="Rendered HTML" htmlStr={htmlStr} onClose={clearHtmlStr} />}
    </>
  );
};

export default AppToasts;
