import { fromJS } from "immutable";
import { notLoadedMap } from "app/utils/constants";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { keyArrToClearHandler, keyArrToLoadDetailedHandler } from "app/utils/redux/reducerHandlers";
import { Action } from "app/utils/redux/standardActions";
import { actionTypes } from "./actions";

const initialState = fromJS({
  detailed: {
    recurrenceRule: notLoadedMap,
  },
});

function reducer(state = initialState, action: Action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_DETAILED_RECURRENCERULE]: keyArrToLoadDetailedHandler(["detailed", "recurrenceRule"]),
    [actionTypes.CLEAR_DETAILED_RECURRENCERULE]: keyArrToClearHandler(["detailed", "recurrenceRule"]),
  });
}

export default reducer;
