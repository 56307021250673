import { Record } from "immutable";
import { ItemTypes, emptyList, emptyMap } from "app/utils/constants";

export const AssignmentDetailedRecord = Record({
  pk: null,
  uuid: null,
  name: "",

  assignment_state: null,

  tags: emptyList,
  errors: emptyList,
  warnings: emptyList,

  start: null,
  end: null,
  minutes: 0,
  minutes_work: 0,
  minutes_work_custom: null,
  units: null,
  units_custom: null,

  has_custom_labels: false,
  labels: emptyList,
  location: null,

  is_capable_team: null,
  is_out_of_scope: false,

  resources: emptyList,
  resources_reqs_assigned: emptyList,
  resource_assigned_requirements_dict: emptyMap,
  stock_items: emptyList,
  stock_items_reqs_assigned: emptyList,

  planned_start: null,
  planned_end: null,
  planned_minutes: 0,
  planned_minutes_work: 0,
  planned_minutes_work_custom: null,
  planned_units: null,
  planned_units_custom: null,
  planned_is_capable_team: null,
  planned_location: null,
  planned_resources: emptyList,
  planned_resources_reqs_assigned: emptyList,
  planned_stock_items: emptyList,
  planned_stock_items_reqs_assigned: emptyList,

  realised_start: null,
  realised_end: null,
  realised_minutes: 0,
  realised_minutes_work: 0,
  realised_minutes_work_custom: null,
  realised_units: null,
  realised_units_custom: null,
  realised_is_capable_team: null,
  realised_location: null,
  realised_resources: emptyList,
  realised_resources_reqs_assigned: emptyList,
  realised_stock_items: emptyList,
  realised_stock_items_reqs_assigned: emptyList,

  lock_all: false,
  exceptions: emptyList,

  notes: "",
  calendar_event_id: null,

  work: null,
  work_labels: emptyList,

  project: null,
  type: ItemTypes.assignment,
});

export const CalendarRecord = Record({
  calendar_links: emptyList,
  description: "",
  name: "",
  pk: null,
  shared: false,
});

export const CalendarEventRecord = Record({
  calendar: null,

  name: "",
  description: "",

  color: "",

  start_date: null,
  end_date: null,
  is_all_day: false,

  assignment_rule: null,
  recurrence: emptyMap,
});

export const FeasibleSlotRecord = Record({
  // pk: null,
  start: null,
  end: null,
  // color: '',
  work: null,
  // resource: null,
  type: ItemTypes.feasibleSlot,
});

export const ColorSlotRecord = Record({
  pk: null,
  start: null,
  end: null,
  color: "",
  work: null,
  resource: null,
  type: ItemTypes.colorSlot,
});

export const RecurrenceRuleRecord = Record({
  recurrence: emptyMap,

  date_from: null,
  date_until: null,

  frequency: 0,
  interval: 1,
  count: null,

  by_setpos: emptyList,
  by_weekday: emptyList,
  by_monthday: emptyList,
  by_week: emptyList,
  by_month: emptyList,
});

export const ResourceSimpleRecord = Record({
  breed: null,
  color_default: "",
  name: "",
  pk: null,
  id_ext: null,
  email: "",
  description: "",
  description_short: "",
  labels: emptyList,
  location_home: null,
  type: ItemTypes.resource,
  is_human: null,
  created_date: null,
  modified_date: null,
});

export const ResourceDetailedRecord = Record({
  activations: emptyList,

  breed: null,
  color_default: "",
  cost_per_hour: null,
  calendar_links: emptyList,
  clashing_resources: emptyList,
  date_of_birth: null,
  email: "",

  blocks: emptyList,
  labels: emptyList,
  location_base: null,
  location_home: null,

  is_mobile: false,
  is_external: false,
  name: "",
  description: "",
  description_short: "",
  operating_skills_requirements: emptyList,
  pk: null,

  resource_states_at_moments: emptyList,
  skill_links: emptyList,

  id_ext: null,
  min_minutes_between_assignment_blocks: null,
  state_transition_matrix: null,

  plannable_state: null,

  type: ItemTypes.resource,

  created_date: null,
  modified_date: null,
});

export const ResourceBreedRecord = Record({
  pk: null,
  name: "",
  id_ext: null,
  description: "",
  description_short: "",
  is_default: false,
  is_human: false,
  plannable_resources: emptyList,
  template_resources: emptyList,
});

export const UserProfileRecord = Record({});

export const WorkSimpleRecord = Record({
  pk: null,
  id_ext: null,

  name: "",
  description_short: "",
  description: "",
  url: "",
  group_name: null,

  str: "",

  start: null,
  end: null,
  window_start_follows_project: null,
  window_end_follows_project: null,

  labels: emptyList,
  locations: emptyList,
  tags: emptyList,

  importance: null,
  project: null,
  entity: null,

  earliest_start: null,
  earliest_start_lower_bound: null,
  earliest_end: null,
  earliest_end_lower_bound: null,
  latest_start: null,
  latest_start_upper_bound: null,
  latest_end: null,
  latest_end_upper_bound: null,

  duration: null,
  smallest_fragment: null,
  largest_fragment: null,

  minutes_planned: null,
  minutes_unplanned: null,
  minutes_realised: null,

  work_state: null,
  work_type: null,
  on_hold: false,

  window_minutes: null,
  window_start_delta_minutes: null,

  created_date: null,
  modified_date: null,
  type: ItemTypes.work,

  __historical: false,
  __no_ftf: false,
});

export const WorkDetailedRecord = Record({
  loading: false,

  pk: null,
  id_ext: null,

  name: "",
  description: "",
  description_short: "",
  url: "",
  group_name: null,

  window_start_follows_project: null,
  window_end_follows_project: null,
  start: null,
  end: null,

  planned_start: null,
  planned_end: null,
  realised_start: null,
  realised_end: null,

  earliest_start_lower_bound: null,
  earliest_start: null,
  earliest_end: null,
  earliest_end_lower_bound: null,
  latest_start: null,
  latest_start_upper_bound: null,
  latest_end: null,
  latest_end_upper_bound: null,

  duration: null,
  duration_planned: null,

  smallest_fragment: null,
  largest_fragment: null,
  modulo_fragment: null,

  minutes_planned: null,
  minutes_realised: null,
  minutes_unplanned: null,

  work_type: null,
  work_state: null,
  on_hold: false,

  entity: null,
  project: null,
  network: null,
  importance: null,
  skills_requirements: emptyList,
  stock_requirements: emptyList,
  successor_relations: emptyList,
  predecessor_relations: emptyList,
  assignments: emptyList,
  assignment_templates: emptyList,

  calendar_links: emptyList,
  calendar_links_as_conditional: emptyList,

  labels: emptyList,
  locations: emptyList,
  tags: emptyList,

  works_conditionals: emptyList,
  works_resources_claims: emptyList,
  works_spans: emptyList,
  // TODO figure out which one to use...
  works_same_resources: emptyList,
  works_same_resources_old: emptyList,
  works_same_resources_new: emptyList,

  created_date: null,
  modified_date: null,

  fragments: emptyList,
  start_times: emptyList,
  modulo_start_time: null,

  is_out_of_scope: false,
  is_template: false, // TODO is this used?
  is_fragmentable: null,
  is_concurrent: null,
  max_concurrent_assignments: null,

  color_default: "",
  color_default_as_conditional: "",

  resource_preference: null,
  calendars: emptyList,

  possible_start_times: emptyMap,
  possible_fragments: emptyMap,

  window_minutes: null,
  window_start_delta_minutes: null,

  units: null,
  units_skill: null,
  units_planned: null,
  units_realised: null,
  units_unplanned: null,

  is_valid: null,

  type: ItemTypes.work,

  __historical: false,
  __no_ftf: false,
});

export const ProjectSimpleRecord = Record({
  pk: null,
  id_ext: null,

  name: "",
  description_short: "",
  description: "",
  url: "",

  project_state: null,

  window_start: null,
  window_end: null,

  created_date: null,
  modified_date: null,
  type: ItemTypes.project,
});

export const ProjectDetailedRecord = Record({
  pk: null,
  id_ext: null,

  name: "",
  description_short: "",
  description: "",
  url: "",

  project_state: null,

  window_start: null,
  window_end: null,
  planned_start: null,
  planned_end: null,
  realised_start: null,
  realised_end: null,

  works: emptyList,

  type: ItemTypes.project,
});

export const PlanningStateRecord = Record({
  version: null,

  uuid: null,
  uuid_prev_state: null,
  name: null, // TODO not in API yet

  is_valid: null,
  errors: emptyList,
  warnings: emptyList,
  ignored_errors: emptyList,
  ignored_warnings: emptyList,

  penalty: null, // TODO this doesnt correspond with API
  penalty_vector: emptyList,
  penalty_dict: null,
  parametrized_penalty_dict_vector: null,
  parametrized_penalty_vector: null,

  info: emptyMap,
  // Assignments
  assignments: emptyList,
  assignments_to_delete: emptyList,
  // Works
  works: emptyList,
  works_planned_stats_dict: emptyMap,
  // Resources
  resource_assignment_blocks_dict: emptyMap,
  resource_blocks_dict: emptyMap,
  resource_changeovers_dict: emptyMap,
  resource_claims: emptyList, // TODO update backend and introduce support for resource_claims_dict
  // resource_claims_dict: emptyMap,
  resource_rule_results_dict: emptyMap,
  resource_storage_changes_dict: emptyMap,
  resource_travels: emptyList, // TODO update backend and introduce support for resource_travels_dict
  // resource_travels_dict: emptyMap,
  // Stock
  stock_item_levels_dict: emptyMap,
  // Storage
  storage_changes_dict: emptyMap,
  storage_states_dict: emptyMap,
  // General
  general_rule_results: emptyList,

  // Is this the DB/historicalState?
  __historical: false,
  // Is this an engine state or optimistic state?
  __engine: true,
  __optimistic: false,
  // Whether we want to allow deleting the state altogether
  __removable: false,
  // Whether the API has this state stored (so we can update via changes only)
  __stored: false,
  __error: false,
  // Whether the API has this state optimistically only (in history), should still become an engine state then!
  __processed: true,
  __processing: false,

  // The changes are based off the last stored engine state, this way the engine can still figure out the state even
  // when not all intermediate states have been stored.
  // TODO this approach should be deprecated later on, the changes should be kept in a changeset and not on the state
  __changes: emptyList,
  __changes_uuid: null,

  // Describes the uuid we are waiting for to be loaded / created optimistically
  __waiting_changes: emptyList,
  __waiting_for_uuid: null,
  __waiting_for_optimistic_to_engine: false,

  // Flag for async loading of states
  __loaded: true,
  // Which user was responsible for this change
  __user: null,
  // Handy to find out whether this belongs to "history" or not
  __changeSetId: null,
  // When was the state created (or more accurately, when was the actionSet recorded)
  __created: null,
  // Used for actualState
  __actual: false,
  __snapshot: null,

  // Debug flag, state only accessible in debugMode?
  __debug: false,
  // Debug flag indicating an "inconsistent" PlanningState history
  __debug_inconsistent_prev_state: false,
});
