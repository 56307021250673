import InternalDal from "app/utils/InternalDal";

class ResourcesDal {
  constructor(apiUrl) {
    this.dal = new InternalDal(apiUrl);
    this.resourcesUrl = `${this.dal.baseTenantUrl}/resources`;
    this.resourceTemplatesUrl = `${this.dal.baseTenantUrl}/resource-templates`;
    this.breedsUrl = `${this.dal.baseTenantUrl}/resource-breeds`;
  }

  getSimpleResources() {
    const url = `${this.resourcesUrl}/`;

    return this.dal.getter(url, "getSimpleResources");
  }

  getDetailedResource(resourcePk) {
    const url = `${this.resourcesUrl}/detailed/${resourcePk}/`;

    return this.dal.getter(url, "getDetailedResource");
  }

  getResourceWorkTemplates(resourcePk) {
    const url = `${this.resourcesUrl}/${resourcePk}/work_templates`; // TODO change to /work-templates

    return this.dal.getter(url, "getResourceWorkTemplates");
  }

  cloneResource(resourcePk) {
    const url = `${this.dal.baseTenantUrl}/clone-resource/`;

    return this.dal.poster(url, { resource: resourcePk }, "cloneResource");
  }

  createResource(resource) {
    const url = `${this.resourcesUrl}/detailed/`;

    return this.dal.poster(url, resource, "createResource");
  }

  createResourceFromTemplate(resource, resourceTemplatePk) {
    const url = `${this.dal.baseTenantUrl}/create-resource-from-template`;
    const data = { ...resource, resource_template: resourceTemplatePk };

    return this.dal.poster(url, data, "createResourceFromTemplate");
  }

  createTemplateFromResource(resourcePk) {
    const url = `${this.dal.baseTenantUrl}/create-template-from-resource/`;
    const data = { resource: resourcePk };

    return this.dal.poster(url, data, "createTemplateFromResource");
  }

  putResource(resource) {
    const url = `${this.resourcesUrl}/detailed/${resource.pk}/`;

    return this.dal.putter(url, resource, "putResource");
  }

  deleteResource(resourcePk) {
    const url = `${this.resourcesUrl}/${resourcePk}/`;

    return this.dal.deleter(url, "deleteResource");
  }

  patchResourceSkills(resourcePk, skillPks) {
    const url = `${this.resourcesUrl}/detailed/${resourcePk}/`;
    const data = { skills: skillPks };

    return this.dal.patcher(url, data, "patchResourceSkills");
  }

  //
  // TEMPLATES
  //
  getSimpleResourceTemplates() {
    const url = `${this.resourceTemplatesUrl}/`;

    return this.dal.getter(url, "getSimpleResourceTemplates");
  }

  deleteResourceTemplate(resourceTemplatePk) {
    const url = `${this.resourceTemplatesUrl}/${resourceTemplatePk}/`;

    return this.dal.deleter(url, "deleteResourceTemplate");
  }

  //
  // BREEDS
  //

  getSimpleBreeds() {
    const url = `${this.breedsUrl}/`;

    return this.dal.getter(url, "getSimpleBreeds");
  }

  getDetailedBreed(breedPk) {
    const url = `${this.breedsUrl}/${breedPk}/`;

    return this.dal.getter(url, "getDetailedBreed");
  }

  createBreed(breed) {
    const url = `${this.breedsUrl}/`;

    return this.dal.poster(url, breed, "createBreed");
  }

  putBreed(breed) {
    const url = `${this.breedsUrl}/${breed.pk}/`;

    return this.dal.putter(url, breed, "putBreed");
  }

  updateBreedResources(breedPk, resourcePks) {
    const url = `${this.breedsUrl}/${breedPk}/update_plannable_resources`;

    return this.dal.poster(url, resourcePks, "patchBreedResources");
  }

  updateBreedResourceTemplates(breedPk, resourceTemplatePks) {
    const url = `${this.breedsUrl}/${breedPk}/update_template_resources`;

    return this.dal.poster(url, resourceTemplatePks, "patchBreedResourceTemplates");
  }

  deleteBreed(breedPk) {
    const url = `${this.breedsUrl}/${breedPk}/`;

    return this.dal.deleter(url, "deleteBreed");
  }
}

export default ResourcesDal;
