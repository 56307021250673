import { Component, ErrorInfo, ReactNode } from "react";
import RedBox from "redbox-react";
import { Store } from "redux";
import Sentry from "app/core/sentry";
import { getUser, getUserIsInDebugMode, getUserTenant } from "app/features/users/selectors";
import history from "app/history";
import { isNotProduction } from "app/process";
import AppErrorFallBack from "./AppErrorFallBack";

interface AppErrorBoundaryProps {
  store: Store;
  children: ReactNode;
}

interface ErrorState {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

const emptyErrorState = {
  error: null,
  errorInfo: null,
} as ErrorState;

const getUserInfoFromStore = (store: Store) => {
  const storeState = store.getState();
  const user = getUser(storeState);
  const userIsInDebugMode = getUserIsInDebugMode(storeState);
  const tenant = getUserTenant(storeState);

  return {
    userIsInDebugMode,
    username: user.get("username"),
    email: user.get("email"),
    tenantName: tenant.get("name"),
    tenantSchema: tenant.get("schema_name"),
  };
};

class AppErrorBoundary extends Component<AppErrorBoundaryProps> {
  state = emptyErrorState;

  componentDidMount() {
    // NOTE this adds a listener to make sure we reset the error immediately on location changes,
    // enabling the user to browse back and forward in the App.
    history.listen((_location, _action) => this.resetState());
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const errorState = {
      error,
      errorInfo,
    } as ErrorState;

    this.setState(errorState, () => this.captureExceptionWithSentry(error, errorInfo));
  }

  resetState = () => {
    if (this.state.error || this.state.errorInfo) {
      this.setState(emptyErrorState);
    }
  };

  getUserInfo = () => {
    // NOTE that we can't connect the component to redux state,
    // so we use the store directly, which has been passed in via props.
    return getUserInfoFromStore(this.props.store);
  };

  captureExceptionWithSentry = (error: Error, errorInfo: ErrorInfo) => {
    const userInfo = this.getUserInfo();

    Sentry.withScope((scope) => {
      // Map all errorInfo to Sentry scope.
      Object.entries(errorInfo).forEach(([key, value]) => {
        scope.setExtra(key, value);
      });

      // Set gathered user info to Sentry scope.
      scope.setUser(userInfo);
      Sentry.captureException(error);
    });
  };

  render() {
    const { error, errorInfo } = this.state;
    if (!error) {
      // No error just render the children
      return this.props.children;
    }
    if (isNotProduction) {
      // In development, we show the error with RedBox
      return <RedBox error={error} />;
    }

    // In production, we show a user-friendly error message
    return <AppErrorFallBack error={error} errorInfo={errorInfo} debugMode={this.getUserInfo().userIsInDebugMode} />;
  }
}

export default AppErrorBoundary;
