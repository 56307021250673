import InternalDal from "app/utils/InternalDal";

class ResourceStatesDal {
  constructor(apiUrl) {
    this.dal = new InternalDal(apiUrl);
    this.statesUrl = `${this.dal.baseTenantUrl}/resource-states`;
    this.statesAtMomentsUrl = `${this.dal.baseTenantUrl}/resource-states-at-moments`;
    this.matricesUrl = `${this.dal.baseTenantUrl}/resource-state-matrices`;
    this.resetsUrl = `${this.dal.baseTenantUrl}/resource-state-resets`;
    this.transitionsUrl = `${this.dal.baseTenantUrl}/resource-state-transitions`;
  }

  /* States */
  getSimpleResourceStates() {
    const url = `${this.statesUrl}/`;

    return this.dal.getter(url, "getSimpleResourceStates");
  }

  getDetailedResourceState(resourceStatePk) {
    const url = `${this.statesUrl}/${resourceStatePk}/`;

    return this.dal.getter(url, "getDetailedResourceState");
  }

  createResourceState(resourceState) {
    const url = `${this.statesUrl}/`;

    return this.dal.poster(url, resourceState, "createResourceState");
  }

  putResourceState(resourceState) {
    const url = `${this.statesUrl}/${resourceState.pk}/`;

    return this.dal.putter(url, resourceState, "putResourceState");
  }

  deleteResourceState(resourceStatePk) {
    const url = `${this.statesUrl}/${resourceStatePk}/`;

    return this.dal.deleter(url, "deleteResourceState");
  }

  /* Matrices */
  getSimpleResourceStateMatrices() {
    const url = `${this.matricesUrl}/`;

    return this.dal.getter(url, "getSimpleResourceStateMatrices");
  }

  getDetailedResourceStateMatrix(pk) {
    const url = `${this.matricesUrl}/${pk}/`;

    return this.dal.getter(url, "getDetailedResourceStateMatrix");
  }

  createResourceStateMatrix(matrix) {
    const url = `${this.matricesUrl}/`;

    return this.dal.poster(url, matrix, "createResourceStateMatrix");
  }

  patchResourceStateMatrix(matrix) {
    const url = `${this.matricesUrl}/${matrix.pk}/`;

    return this.dal.patcher(url, matrix, "putResourceStateMatrix");
  }

  updateResourceStateMatrixResources(matrixPk, resourcePks) {
    const url = `${this.matricesUrl}/${matrixPk}/update_plannable_resources/`;

    return this.dal.poster(url, resourcePks, "updateResourceStateMatrixResources");
  }

  updateResourceStateMatrixResourceTemplates(matrixPk, resourceTemplatePks) {
    const url = `${this.matricesUrl}/${matrixPk}/update_template_resources/`;

    return this.dal.poster(url, resourceTemplatePks, "updateResourceStateMatrixResourceTemplates");
  }

  patchResourceStateMatrixStates(matrixPk, statePks) {
    const url = `${this.matricesUrl}/${matrixPk}/`;
    const data = { resource_states: statePks };

    return this.dal.patcher(url, data, "patchResourceStateMatrixStates");
  }

  patchResourceStateMatrixFallbackStates(matrixPk, statePks) {
    const url = `${this.matricesUrl}/${matrixPk}/`;
    const data = { fallback_resource_states: statePks };

    return this.dal.patcher(url, data, "patchResourceStateMatrixFallbackStates");
  }

  deleteResourceStateMatrix(pk) {
    const url = `${this.matricesUrl}/${pk}/`;

    return this.dal.deleter(url, "deleteResourceStateMatrix");
  }

  /* Transitions */
  getDetailedResourceStateTransition(pk) {
    const url = `${this.transitionsUrl}/${pk}/`;

    return this.dal.getter(url, "getDetailedResourceStateTransition");
  }

  createResourceStateTransition(matrix) {
    const url = `${this.transitionsUrl}/`;

    return this.dal.poster(url, matrix, "createResourceStateTransition");
  }

  putResourceStateTransition(transition) {
    const url = `${this.transitionsUrl}/${transition.pk}/`;

    return this.dal.putter(url, transition, "putResourceStateTransition");
  }

  deleteResourceStateTransition(pk) {
    const url = `${this.transitionsUrl}/${pk}/`;

    return this.dal.deleter(url, "deleteResourceStateTransition");
  }

  /* Resets */
  getDetailedResourceStateReset(pk) {
    const url = `${this.resetsUrl}/${pk}/`;

    return this.dal.getter(url, "getDetailedResourceStateReset");
  }

  createResourceStateReset(matrix) {
    const url = `${this.resetsUrl}/`;

    return this.dal.poster(url, matrix, "createResourceStateReset");
  }

  putResourceStateReset(reset) {
    const url = `${this.resetsUrl}/${reset.pk}/`;

    return this.dal.putter(url, reset, "putResourceStateReset");
  }

  deleteResourceStateReset(pk) {
    const url = `${this.resetsUrl}/${pk}/`;

    return this.dal.deleter(url, "deleteResourceStateReset");
  }
}

export default ResourceStatesDal;
