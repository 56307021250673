import { EuiHeader } from "@elastic/eui";
import { useMemo } from "react";
import { ReactNode } from "react";
import { MenuItem, NavDropdown, NavItem, Navbar } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import AppSidebarCollapsible from "app/core/components/AppSidebarCollapsible";
import { useDebugMode, useIsDebugTenant } from "app/core/hooks";
import { useAppTenantContext } from "app/core/providers/AppTenantDetectionProvider";
import { useAppTenantUserLinkContext } from "app/core/providers/AppTenantUserLinkProvider";
import { useAppTopNavContext } from "app/core/providers/AppTopNavProvider";
import { useAppUserContext } from "app/core/providers/AppUserProvider";
import { useAppUserTenantsContext } from "app/core/providers/AppUserTenantsProvider";
import ExternalLink from "app/common/components/ExternalLink";
import { CustomIcon, CustomIconWithLabel, Icons } from "app/utils/constants";
import SnapshotStatusBar from "app/features/snapshots/components/SnapshotStatusBar";
import { useTenantNavToUrl, useTenantUrl } from "app/features/tenants/hooks";
import { useToggleDebugMode } from "app/features/users/hooks";
import { tenantsUrl } from "app/features/users/routing";
import { getUserTenantId, getUserTenantName, getUserTenantSchemaName } from "app/features/users/selectors";
import { useWebSocketContext } from "app/features/webSockets/WebSocketProvider";

const showAuthLink = false;
const showTenantProfileLink = false;

const PATHS = {
  AUTH: "login",
  BG_TASKS: "background-tasks",
  PROFILE: "profile",
  SOCKET: "socket",
};

const NavSeparator = () => (
  <li role="presentation">
    {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
    <Navbar.Text>|</Navbar.Text>
  </li>
);

const useUserAccountDropDownItems = () => {
  const { isTenantUserPlanner } = useAppTenantUserLinkContext();
  return useMemo(
    () =>
      [
        {
          icon: Icons.profile,
          title: <FormattedMessage id="Header.userProfile" />,
          href: "/user/profile",
        },
        {
          separator: true,
        },
        isTenantUserPlanner && {
          icon: Icons.changelog,
          title: <FormattedMessage id="Header.about" />,
          href: "/about",
        },
        isTenantUserPlanner && {
          separator: true,
        },
        {
          icon: Icons.logout,
          title: <FormattedMessage id="Header.logout" />,
          href: "/logout",
        },
      ].filter(Boolean),
    [isTenantUserPlanner],
  );
};

const useAPIAdminUrl = () => {
  const tenantId = useSelector(getUserTenantId);
  return `/api/admin/${tenantId}/`;
};

const isNotPublicOrDebugSchema = (schemaName: string) => schemaName !== "public" && schemaName !== "pytest";
const isRegularSchema = isNotPublicOrDebugSchema;

const useTenantAccountDropDownItems = () => {
  /* The isNotPublicTenant check is done because BackgroundTasks, Websocket pages don't exist for the public schema */
  const schemaName = useSelector(getUserTenantSchemaName);
  const isTenant = isRegularSchema(schemaName);

  const debugMode = useDebugMode();

  const apiAdminUrl = useAPIAdminUrl();
  const tenantAuthUrl = useTenantUrl(PATHS.AUTH);
  const tenantBgTasksUrl = useTenantUrl(PATHS.BG_TASKS);
  const tenantProfileUrl = useTenantUrl(PATHS.PROFILE);
  const tenantWebSocketUrl = useTenantUrl(PATHS.SOCKET);

  const menuItemSwitchTenants = {
    icon: Icons.tenants,
    title: <FormattedMessage id="Header.switchTenants" />,
    href: tenantsUrl,
    nav: true,
  };
  const menuItems = debugMode
    ? [
        showTenantProfileLink && {
          icon: Icons.tenant,
          title: "TenantProfiel",
          href: tenantProfileUrl,
          nav: true,
        },
        showTenantProfileLink && {
          separator: true,
          nav: false,
        },
        isTenant && {
          icon: Icons.calculation,
          title: <FormattedMessage id="Header.backgroundTasks" />,
          href: tenantBgTasksUrl,
          nav: true,
        },
        {
          icon: Icons.settings,
          title: <FormattedMessage id="Header.apiAdmin" />,
          href: apiAdminUrl,
          nav: false,
          external: true,
        },
        showAuthLink && {
          icon: "users",
          title: <FormattedMessage id="Header.auth" />,
          href: tenantAuthUrl,
          nav: true,
        },
        isTenant && {
          icon: "plug",
          title: <FormattedMessage id="Header.webSocket" />,
          href: tenantWebSocketUrl,
          nav: true,
        },
        {
          separator: true,
          nav: false,
        },
        menuItemSwitchTenants,
      ].filter(Boolean)
    : [menuItemSwitchTenants];
  return menuItems;
};

const navbarWrapperStyle = {
  // flex: '0 0 50px',
  minHeight: 48,
  height: 48,
  listStyleType: "none",
  backgroundColor: "#222",
};

const activeLinkStyle = {
  color: "white",
  // TODO underline this?
  borderBottom: "1px solid #990037",
  paddingBottom: 3,
};
const activeDropDownLinkStyle = { backgroundColor: "rgba(0, 0, 0, 0.1)" };

const UserAccountTitle = ({ userDisplayName }: { userDisplayName: string }) => (
  <CustomIconWithLabel icon={Icons.user} label={userDisplayName} />
);
const TenantAccountTitle = ({ tenantName }: { tenantName: string }) => (
  <CustomIconWithLabel icon={Icons.tenant} label={tenantName} />
);

const customItemStyle = {
  paddingLeft: 20,
  paddingRight: 20,
};

// @ts-expect-error TODO fix item type
const CustomItem = ({ item, nav = true, activeStyle = activeLinkStyle }) => {
  if (item.separator) {
    // @ts-expect-error TODO fix NavSeparator type
    return nav ? <NavSeparator style={customItemStyle} /> : <MenuItem style={customItemStyle} divider />;
  }

  const { href, icon, title, external } = item;

  const label = <CustomIconWithLabel icon={icon} label={title} />;

  let link;
  if (external) {
    // @ts-expect-error TODO fix ExternalLink type
    link = <ExternalLink href={href} label={label} style={customItemStyle} />;
  } else if (nav) {
    link = (
      <NavLink to={href} activeStyle={activeStyle} activeClassName="active" style={customItemStyle}>
        {label}
      </NavLink>
    );
  } else {
    link = (
      <a href={href} style={customItemStyle}>
        {label}
      </a>
    );
  }

  return <li role="presentation">{link}</li>;
};

const WebsocketIcon = () => {
  // @ts-expect-error TODO fix WebSocketContext type
  const { isConnected } = useWebSocketContext();
  return (
    <span style={{ color: isConnected ? "green" : "red" }}>
      <CustomIcon icon={Icons.webSocket} />
    </span>
  );
};

const WebsocketNavItem = () => (
  <NavItem key="connectivity" onClick={useTenantNavToUrl("socket")}>
    <WebsocketIcon />
  </NavItem>
);

const DebugModeNavItem = ({ debugMode, onToggleDebugMode }: { debugMode: boolean; onToggleDebugMode: () => void }) => (
  // eslint-disable-next-line formatjs/no-literal-string-in-jsx
  <NavItem onClick={onToggleDebugMode}>{`Debug: ${debugMode ? "on" : "off"}`}</NavItem>
);

const AppTopNavBarTenantUserMenu = () => {
  const { userIsAllowedToDebug, userDisplayName } = useAppUserContext();
  const { hasMultipleTenants } = useAppUserTenantsContext();

  const debugMode = useDebugMode();
  const onToggleDebugMode = useToggleDebugMode();
  const userTenantName = useSelector(getUserTenantName);
  const userTenantSchemaName = useSelector(getUserTenantSchemaName);
  const isRegularTenant = isRegularSchema(userTenantSchemaName);

  const userAccountTitle = <UserAccountTitle userDisplayName={userDisplayName} />;
  const userAccountDropDownItems = useUserAccountDropDownItems();

  const tenantAccountTitle = <TenantAccountTitle tenantName={userTenantName} />;
  const tenantAccountDropDownItems = useTenantAccountDropDownItems();

  const debugModeToggle = userIsAllowedToDebug && (
    <DebugModeNavItem debugMode={debugMode} onToggleDebugMode={onToggleDebugMode} />
  );

  const showWebSocketItem = debugMode && isRegularTenant;
  const webSocketItem = showWebSocketItem && <WebsocketNavItem />;

  const tenantNavItem = hasMultipleTenants ? (
    <NavDropdown id="dropdown-navbar-tenant" key="tenant" title={tenantAccountTitle}>
      {tenantAccountDropDownItems.map((item, idx) => (
        // @ts-expect-error TODO fix item type
        <CustomItem item={item} key={idx} nav={item.nav} activeStyle={activeDropDownLinkStyle} />
      ))}
    </NavDropdown>
  ) : (
    <NavItem key="tenant" disabled={true}>
      <a>{tenantAccountTitle}</a>
    </NavItem>
  );
  const userNavItem = (
    <NavDropdown className="" id="dropdown-navbar-user" key="user" title={userAccountTitle}>
      {userAccountDropDownItems.map((item, idx) => (
        // @ts-expect-error TODO fix item type
        <CustomItem item={item} key={idx} nav={!item.separator} activeStyle={activeDropDownLinkStyle} />
      ))}
    </NavDropdown>
  );

  const navItemsRight = [debugModeToggle, webSocketItem, tenantNavItem, userNavItem].filter(Boolean);

  return <>{navItemsRight}</>;
};

const AppTopNavbar = () => {
  const { userProfile, userLoaded, userHasTenant } = useAppUserContext();
  const { isTenantUserPlanner } = useAppTenantUserLinkContext();
  const { isTenantLoaded } = useAppTenantContext();
  const { isTopNavHidden } = useAppTopNavContext();
  const isDebugTenant = useIsDebugTenant();
  if (isTopNavHidden) return null;

  const collapsibleSidebar = <AppSidebarCollapsible />;

  const showTenantUserMenu = userLoaded && userProfile;
  const tenantUserMenu = showTenantUserMenu ? <AppTopNavBarTenantUserMenu /> : null;

  const showSnapshotBar = !isDebugTenant && userHasTenant && isTenantLoaded && isTenantUserPlanner;
  const snapshotBar = showSnapshotBar && <SnapshotStatusBar />;
  const headerSections = showTenantUserMenu
    ? [
        {
          items: [collapsibleSidebar],
          borders: "none",
        },
        {
          items: [snapshotBar].filter(Boolean),
          borders: "none",
          side: "right",
        },
        {
          items: [tenantUserMenu],
          borders: "none",
          side: "right",
        },
      ]
    : [];

  return (
    <AppTopNavbarWrapper>
      {/* @ts-expect-error TODO fix headerSections type */}
      <EuiHeader className="EuiHeaderNav" theme={"dark"} position={"fixed"} sections={headerSections} />
    </AppTopNavbarWrapper>
  );
};

export const AppTopNavbarWrapper = ({ children }: { children?: ReactNode }) => (
  <div style={navbarWrapperStyle}>{children}</div>
);

export default AppTopNavbar;
