export const manageBlocksUrl = "manage/blocks";
export const getBlockDetailsUrl = (pk: string | number) => `${manageBlocksUrl}/${pk}`;
export const getBlockEditUrl = (pk: string | number) => `${manageBlocksUrl}/${pk}/edit`;
export const getBlockStartEventNewUrl = (pk: string | number) => `${getBlockDetailsUrl(pk)}/events/start/new`;
export const getBlockStartEventDetailsUrl = (pk: string | number, eventPk: string | number) =>
  `${getBlockDetailsUrl(pk)}/events/start/${eventPk}`;

export const getBlockConditionalEventNewUrl = (pk: string | number) =>
  `${getBlockDetailsUrl(pk)}/events/conditional/new`;
export const getBlockConditionalEventDetailsUrl = (pk: string | number, eventPk: string | number) =>
  `${getBlockDetailsUrl(pk)}/events/conditional/${eventPk}`;

export const getBlockEndEventNewUrl = (pk: string | number) => `${getBlockDetailsUrl(pk)}/events/end/new`;

export const newBlockUrl = `${manageBlocksUrl}/new`;
