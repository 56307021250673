import { fromJS } from "immutable";
import { isLoadingMap, notLoadedMap } from "app/utils/constants";
import { arrayToPkMap } from "app/utils/immutableUtils";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { actionTypes } from "app/features/work/actions";

const initialState = fromJS({
  all: notLoadedMap,
  detailed: notLoadedMap,
});

function handleLoadWorkPriorities(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("all", isLoadingMap);
  }
  if (action.status === "done") {
    const prioritiesJS = action.payload.body.results;
    const prioritiesMap = arrayToPkMap(prioritiesJS, "workPriority");
    state = state.set("all", prioritiesMap);
  }

  return state;
}

function handleLoadDetailedWorkPriority(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("detailed", isLoadingMap);
  }
  if (action.status === "done") {
    state = state.set("detailed", fromJS(action.payload.body));
  }
  return state;
}

function handleClearWorkPriorities(prevState, _action) {
  return prevState.set("all", notLoadedMap);
}

function workPrioritiesReducer(state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_WORK_PRIORITIES]: handleLoadWorkPriorities,
    [actionTypes.LOAD_DETAILED_WORK_PRIORITY]: handleLoadDetailedWorkPriority,
    [actionTypes.CLEAR_WORK_PRIORITIES]: handleClearWorkPriorities,
  });
}

export default workPrioritiesReducer;
