import { fromJS } from "immutable";
import { notLoadedMap } from "app/utils/constants";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { keyArrToClearHandler, keyArrToLoadDetailedHandler, State } from "app/utils/redux/reducerHandlers";
import { Action } from "app/utils/redux/standardActions";
import { actionTypes } from "app/features/workResourcePreference/actions";

const initialState = fromJS({ detailed: notLoadedMap });

function reducer(state: State = initialState, action: Action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_DETAILED_WORK_RESOURCE_PREFERENCE]: keyArrToLoadDetailedHandler(["detailed"]),
    [actionTypes.CLEAR_DETAILED_WORK_RESOURCE_PREFERENCE]: keyArrToClearHandler(["detailed"]),
  });
}

export default reducer;
