import { getEmptyPromise } from "app/utils/dal";
import CalendarsDal from "./dal";

export const actionTypes = {
  LOAD_SIMPLE_CALENDARS_ASYNC: "calendars/LOAD_SIMPLE_CALENDARS_ASYNC",
  LOAD_DETAILED_CALENDAR_ASYNC: "calendars/LOAD_DETAILED_CALENDAR_ASYNC",
  CLEAR_SIMPLE_CALENDARS: "calendars/CLEAR_SIMPLE_CALENDARS",
  CLEAR_DETAILED_CALENDAR: "calendars/CLEAR_DETAILED_CALENDAR",
  CREATE_CALENDAR: "calendars/CREATE_CALENDAR",
  UPDATE_CALENDAR: "calendars/UPDATE_CALENDAR",
  DELETE_CALENDAR: "calendars/DELETE_CALENDAR",

  LOAD_CALENDAR_PREVIEW_ASYNC: "calendars/LOAD_CALENDAR_PREVIEW_ASYNC",

  UPDATE_CALENDAR_LINKS_RESOURCE: "calendars/UPDATE_CALENDAR_LINKS_RESOURCE",
  UPDATE_CALENDAR_LINKS_STOCK_ITEM: "calendars/UPDATE_CALENDAR_LINKS_STOCK_ITEM",
  UPDATE_CALENDAR_LINKS_WORK: "calendars/UPDATE_CALENDAR_LINKS_WORK",

  LOAD_DETAILED_EVENT_ASYNC: "calendars/LOAD_DETAILED_EVENT_ASYNC",
  CLEAR_DETAILED_EVENT: "calendars/CLEAR_DETAILED_EVENT",
  CREATE_EVENT: "calendars/CREATE_EVENT",
  UPDATE_EVENT: "calendars/UPDATE_EVENT",
  DELETE_EVENT: "calendars/DELETE_EVENT",
  PATCH_EVENT: "calendars/PATCH_EVENT",

  CREATE_RULE: "calendars/CREATE_RULE",
  UPDATE_RULE: "calendars/UPDATE_RULE",
  DELETE_RULE: "calendars/DELETE_RULE",
  LOAD_DETAILED_RULE: "calendars/LOAD_DETAILED_RULE",
};

const actionCreators = {
  loadSimpleCalendars() {
    const dal = new CalendarsDal();
    const actionPromise = dal.getSimpleCalendars();
    return {
      type: actionTypes.LOAD_SIMPLE_CALENDARS_ASYNC,
      payload: actionPromise,
    };
  },

  clearSimpleCalendars() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_SIMPLE_CALENDARS,
      payload: actionPromise,
    };
  },

  loadDetailedCalendar(calendarPk) {
    const dal = new CalendarsDal();
    const actionPromise = dal.getDetailedCalendar(calendarPk);
    return {
      type: actionTypes.LOAD_DETAILED_CALENDAR_ASYNC,
      payload: actionPromise,
    };
  },

  clearDetailedCalendar() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_DETAILED_CALENDAR,
      payload: actionPromise,
    };
  },

  createCalendar(calendar) {
    const dal = new CalendarsDal();
    const actionPromise = dal.createCalendar(calendar);
    return {
      type: actionTypes.CREATE_CALENDAR,
      payload: actionPromise,
    };
  },

  updateCalendar(calendar) {
    const dal = new CalendarsDal();
    const actionPromise = dal.putCalendar(calendar);
    return {
      type: actionTypes.UPDATE_CALENDAR,
      payload: actionPromise,
    };
  },

  deleteCalendar(calendarPk) {
    const dal = new CalendarsDal();
    const actionPromise = dal.deleteCalendar(calendarPk);
    return {
      type: actionTypes.DELETE_CALENDAR,
      payload: actionPromise,
    };
  },

  loadCalendarPreview(calendarPk, startDate, endDate) {
    const dal = new CalendarsDal();
    const actionPromise = dal.getCalendarPreview(calendarPk, startDate, endDate);
    return {
      type: actionTypes.LOAD_CALENDAR_PREVIEW_ASYNC,
      payload: actionPromise,
    };
  },

  updateCalendarLinksResource(resourcePk, links, calendarType) {
    const dal = new CalendarsDal();
    const actionPromise = dal.saveCalendarLinksResource(resourcePk, links, calendarType);

    return {
      type: actionTypes.UPDATE_CALENDAR_LINKS_RESOURCE,
      payload: actionPromise,
    };
  },

  updateCalendarLinksStockItem(stockItemPk, links, calendarType) {
    const dal = new CalendarsDal();
    const actionPromise = dal.saveCalendarLinksStockItem(stockItemPk, links, calendarType);

    return {
      type: actionTypes.UPDATE_CALENDAR_LINKS_RESOURCE,
      payload: actionPromise,
    };
  },

  updateCalendarLinksWork(workPk, links, calendarType) {
    const dal = new CalendarsDal();
    const actionPromise = dal.saveCalendarLinksWork(workPk, links, calendarType);

    return {
      type: actionTypes.UPDATE_CALENDAR_LINKS_WORK,
      payload: actionPromise,
    };
  },

  //
  // Load detailed time moment
  //
  loadDetailedEvent(eventPk) {
    const dal = new CalendarsDal();
    const actionPromise = dal.getDetailedEvent(eventPk);

    return {
      type: actionTypes.LOAD_DETAILED_EVENT_ASYNC,
      payload: actionPromise,
    };
  },

  clearDetailedEvent() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_DETAILED_EVENT,
      payload: actionPromise,
    };
  },

  createEvent(event) {
    const dal = new CalendarsDal();
    const actionPromise = dal.createEvent(event);
    return {
      type: actionTypes.CREATE_EVENT,
      payload: actionPromise,
    };
  },

  updateEvent(event) {
    const dal = new CalendarsDal();
    const actionPromise = dal.putEvent(event);
    return {
      type: actionTypes.UPDATE_EVENT,
      payload: actionPromise,
    };
  },

  deleteEvent(eventPk) {
    const dal = new CalendarsDal();
    const actionPromise = dal.deleteEvent(eventPk);
    return {
      type: actionTypes.DELETE_EVENT,
      payload: actionPromise,
    };
  },

  patchEvent(eventPk, data) {
    const dal = new CalendarsDal();
    const actionPromise = dal.patchEvent(eventPk, data);
    return {
      type: actionTypes.PATCH_EVENT,
      payload: actionPromise,
    };
  },

  createRule(rule) {
    const dal = new CalendarsDal();
    const actionPromise = dal.createRule(rule);
    return {
      type: actionTypes.CREATE_RULE,
      payload: actionPromise,
    };
  },

  updateRule(rule) {
    const dal = new CalendarsDal();
    const actionPromise = dal.putRule(rule);
    return {
      type: actionTypes.UPDATE_RULE,
      payload: actionPromise,
    };
  },

  deleteRule(rulePk) {
    const dal = new CalendarsDal();
    const actionPromise = dal.deleteRule(rulePk);
    return {
      type: actionTypes.DELETE_RULE,
      payload: actionPromise,
    };
  },

  loadDetailedRule(rulePk) {
    const dal = new CalendarsDal();
    const actionPromise = dal.getDetailedRule(rulePk);

    return {
      type: actionTypes.LOAD_DETAILED_EVENT_ASYNC,
      payload: actionPromise,
    };
  },
};

export default actionCreators;
