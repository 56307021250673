//
// Application content wrapper
//
import { Property } from "csstype";
import { ReactNode } from "react";
import AppContentSwitch from "app/core/components/AppContentSwitch";

const contentWrapperStyle = {
  height: "100%",
  width: "100%",
  borderLeft: "1px solid rgba(0, 0, 0, 0.05)",
  background: "#e4e4e4",
  overflowY: "auto" as Property.OverflowY,
};

interface AppContentWrapperProps {
  children: ReactNode;
}

export const AppContentWrapper = ({ children }: AppContentWrapperProps) => (
  <div style={contentWrapperStyle}>
    <div className="content content-full">{children}</div>
  </div>
);

const AppContent = () => (
  <AppContentWrapper>
    <AppContentSwitch />
  </AppContentWrapper>
);

export default AppContent;
