import { configureStore } from "@reduxjs/toolkit";
import { applyMiddleware, combineReducers, compose, legacy_createStore as reduxCreateStore, Reducer } from "redux";
import thunkMiddleware from "redux-thunk";
import promiseMiddleware from "./reduxPromiseMiddleware";

//
// Custom createStore for this project.
// Wraps redux createStore in order to use middleware.
//

function customCreateStore(appReducer: Reducer) {
  // combine main app reducer under `app` with utility reducers
  // that need to be separate and not Immutable
  // e.g. routing, forms
  const reducer = combineReducers({
    app: appReducer,
  });

  const middlewares = [thunkMiddleware, promiseMiddleware];

  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  // @ts-expect-error TODO fix types for reduxCreateStore
  const store = reduxCreateStore(reducer, composeEnhancers(applyMiddleware(...middlewares)));

  return store;
}

// TODO use this as default export for @reduxjs/toolkit
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function customConfigureStore(appReducer: Reducer) {
  return configureStore({
    reducer: { app: appReducer },

    // @ts-expect-error TODO fix types for middleware
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(promiseMiddleware),
  });
}

export default customCreateStore;
