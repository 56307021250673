import { createContext, ReactNode, useContext } from "react";
import useToggleState from "app/common/hooks/useToggleState";

const defaultAppTopNavContextValue = {
  isTopNavShown: true,
  isTopNavHidden: false,
  showTopNav: () => {},
  hideTopNav: () => {},
  toggleTopNav: () => {},
};

const AppTopNavContext = createContext(defaultAppTopNavContextValue);
const AppTopNavProvider = ({ children }: { children: ReactNode }) => {
  const { value: isTopNavShown, on: showTopNav, off: hideTopNav, toggle: toggleTopNav } = useToggleState(true);

  const contextValue = {
    isTopNavShown,
    isTopNavHidden: !isTopNavShown,
    showTopNav,
    hideTopNav,
    toggleTopNav,
  };

  return <AppTopNavContext.Provider value={contextValue}>{children}</AppTopNavContext.Provider>;
};

export const useAppTopNavContext = () => {
  const context = useContext(AppTopNavContext);
  if (context === undefined) {
    throw new Error("useAppTopNav must be used within a AppTopNavbarProvider");
  }
  return context;
};

export default AppTopNavProvider;
