export const manageReportsUrl = "manage/reports";

const manageMetricsUrl = `${manageReportsUrl}/metrics`;
export const getMetricDetailsUrl = (pk: number | string) => `${manageMetricsUrl}/${pk}`;
export const getMetricEditUrl = (pk: number | string) => `${manageMetricsUrl}/${pk}/edit`;
export const newMetricUrl = getMetricDetailsUrl("new");

const manageFormulasUrl = `${manageReportsUrl}/formulas`;
export const getFormulaDetailsUrl = (pk: number | string) => `${manageFormulasUrl}/${pk}`;
export const getFormulaEditUrl = (pk: number | string) => `${manageFormulasUrl}/${pk}/edit`;
export const newFormulaUrl = getFormulaDetailsUrl("new");
