import { useCallback } from "react";
import { useSelector } from "react-redux";
import { getTenantUrl } from "app/features/tenants/routing";
import { getUserTenantId } from "app/features/users/selectors";
import history from "app/history";

export const useNav = () => useCallback((url: string) => history.push(url), []);

export const useNavBack = () => useCallback(() => history.goBack(), []);

export const useNavToUrl = (url: string) => {
  const nav = useNav();
  return useCallback(() => nav(url), [nav, url]);
};

export const useNavToUrlOrUndefined = (url?: string) => {
  const navToUrl = useNavToUrl(url || "");
  return url ? navToUrl : undefined;
};

export const useNavReplace = () => useCallback((url: string) => history.replace(url), []);

export const useNavReplaceUrl = (url: string) => {
  const navReplace = useNavReplace();
  return useCallback(() => navReplace(url), [url, navReplace]);
};

export const useTenantId = () => useSelector(getUserTenantId);

// Prefix url with tenantId
export const useTenantUrl = (url: string) => {
  const tenantId = useTenantId();
  return getTenantUrl(tenantId, url);
};

export const useTenantUrlBuilder = () => {
  const tenantId = useTenantId();
  return useCallback((url: string) => getTenantUrl(tenantId, url), [tenantId]);
};

// Callback without url param yet, usage: tenantNav(url);
export const useTenantNav = () => {
  const tenantId = useTenantId();
  const nav = useNav();
  return useCallback((url: string) => nav(getTenantUrl(tenantId, url)), [nav, tenantId]);
};

// Callback with url param, allows to navigate to given url when invoked. Usage: tenantNavToUrl();
export const useTenantNavToUrl = (url: string) => {
  const tenantNav = useTenantNav();
  return useCallback(() => {
    tenantNav(url);
  }, [tenantNav, url]);
};
