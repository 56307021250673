import InternalDal from "app/utils/InternalDal";

type Tenant = unknown;

interface GetTenantsResponse {
  body: { results: Tenant[] };
}

interface SwitchTenantResponse {
  body: { success: boolean };
}

class TenantDal {
  private dal: InternalDal;

  constructor(apiUrl?: string) {
    this.dal = new InternalDal(apiUrl);
  }

  getTenants() {
    const url = this.dal.makePublicUrl("tenants");
    return this.dal.getter<GetTenantsResponse>(url, "getTenants");
  }

  switchTenant(schemaName: string) {
    const url = this.dal.makePublicUrl("tenants/switch");
    const data = { schemaName };

    return this.dal.poster<SwitchTenantResponse>(url, data, "switchTenant");
  }
}

export default TenantDal;
