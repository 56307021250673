import { FormattedMessage } from "react-intl";
import CalloutWarning from "app/common/components/CalloutWarning";
import { CustomIconWithLabel, Icons } from "app/utils/constants";

const PanelWarnings = ({ warnings }) => (
  <div className="panel-warnings">
    <CalloutWarning
      title={<CustomIconWithLabel icon={Icons.warning} label={<FormattedMessage id={"Panel.warnings"} />} />}
    >
      <table>
        <tbody>
          {warnings.map((w) => (
            <tr key={w.get("pk") || w.get("title")}>
              <td>{w.get("title")}</td>
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
              <td>&nbsp;&nbsp;-&nbsp;&nbsp;</td>
              <td>
                <i>{w.get("description")}</i>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </CalloutWarning>
  </div>
);

export default PanelWarnings;
