import { Location } from "history";
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react";
import { Router, useLocation } from "react-router-dom";
import history from "app/history";

// This is a wrapper around the react-router-dom Router with our own custom "PrevLocation"-tracker. In the future we
// should be able to do more advanced route-tracking and providing an "in-app" history of the last N visited pages.

export const usePrevLocation = () => useContext(PrevLocationContext);

const PrevLocationContext = createContext<Location | null>(null);

const PrevLocationProvider = ({ children }: { children: ReactNode }) => {
  const [prevLocation, setPrevLocation] = useState<Location | null>(null);
  const currentLocationRef = useRef<Location | null>(null);

  const location = useLocation();
  useEffect(() => {
    setPrevLocation(currentLocationRef.current);
    currentLocationRef.current = location;
  }, [location]);

  return <PrevLocationContext.Provider value={prevLocation}>{children}</PrevLocationContext.Provider>;
};

const AppRouterProvider = ({ children }: { children: ReactNode }) => (
  <Router history={history}>
    <PrevLocationProvider>{children}</PrevLocationProvider>
  </Router>
);

export default AppRouterProvider;
