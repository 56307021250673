import { createContext, ReactNode, useContext, useEffect, useRef } from "react";

const AppScrollbarWidthContext = createContext(0);

const useMeasureScrollbarWidth = () => {
  const widthRef = useRef(0);

  useEffect(() => {
    // Add temporary div and measures it
    const tempDiv = document.createElement("div");

    // Make box scrollable
    tempDiv.style.overflow = "scroll";

    // Append box to document
    document.body.appendChild(tempDiv);

    // Store measure
    widthRef.current = tempDiv.offsetWidth - tempDiv.clientWidth;

    // Remove from document again
    document.body.removeChild(tempDiv);
  }, []);

  return widthRef.current;
};

const AppScrollbarWidthProvider = ({ children }: { children: ReactNode }) => {
  const scrollbarWidth = useMeasureScrollbarWidth();

  return <AppScrollbarWidthContext.Provider value={scrollbarWidth}>{children}</AppScrollbarWidthContext.Provider>;
};

export const useAppScrollbarWidth = () => useContext(AppScrollbarWidthContext);

export default AppScrollbarWidthProvider;
