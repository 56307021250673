import InternalDal from "app/utils/InternalDal";
import { Label } from "app/features/labels/types";

class LabelsDal {
  private readonly labelsUrl: string;
  private dal: InternalDal;

  constructor(apiUrl?: string) {
    this.dal = new InternalDal(apiUrl);
    this.labelsUrl = `${this.dal.baseTenantUrl}/labels`;
  }

  getSimpleLabels() {
    const url = `${this.labelsUrl}/`;

    return this.dal.getter(url, "getSimpleLabels");
  }

  getDetailedLabel(labelPk: number | string) {
    const url = `${this.labelsUrl}/${labelPk}/`;

    return this.dal.getter(url, "getDetailedLabel");
  }

  createLabel(label: Label) {
    const url = `${this.labelsUrl}/`;

    return this.dal.poster(url, label, "createLabel");
  }

  putLabel(label: Label) {
    const url = `${this.labelsUrl}/${label.pk}/`;

    return this.dal.putter(url, label, "putLabel");
  }

  deleteLabel(labelPk: number | string) {
    const url = `${this.labelsUrl}/${labelPk}/`;

    return this.dal.deleter(url, "deleteLabel");
  }
}

export default LabelsDal;
