import { newCalculationUrl } from "app/features/calculations/routing";

// Snapshots
export const manageSnapshotsUrl = "snapshots";
export const importSnapshotUrl = "snapshots/import";
export const getSnapshotDetailsUrl = (snapshotId: number | string) => `snapshots/${snapshotId}/details`;
export const getSnapshotReviewUrl = (snapshotId: number | string) => `snapshots/${snapshotId}`;
export const getSnapshotCalculationUrl = (snapshotId: number | string) =>
  newCalculationUrl + `?snapshot=${snapshotId}&active=0`;

// Snapshot Scopes
export const manageSnapshotScopesUrl = "snapshots/scopes";
export const newSnapshotScopeUrl = "snapshots/scopes/new";
export const getSnapshotScopeRecurrenceUrl = (snapshotScopePk: number | string, recurrencePk: number | string) =>
  `snapshots/scopes/${snapshotScopePk}/recurrence/${recurrencePk}`;
