import { useEffect, useMemo, useState } from "react";
import { safeParseJSON } from "app/utils/json";

const useLocalStorageState = (key: string, defaultValue: unknown = null) => {
  const initialValue = useMemo(() => {
    const valueStr = localStorage.getItem(key);
    if (valueStr === null) {
      return defaultValue;
    }

    return safeParseJSON(valueStr) ?? defaultValue;
  }, [key, defaultValue]);
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export default useLocalStorageState;
