import { ReactNode, memo, useMemo } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { Store as ReduxStore } from "redux";
import AppErrorBoundary from "app/core/components/AppErrorBoundary";
import AppUpdateAvailableModal from "app/core/components/AppUpdateAvailableModal";
import { useIsDebugTenant } from "app/core/hooks";
import AppIntlProvider from "app/core/providers/AppIntlProvider";
import AppNowProvider from "app/core/providers/AppNowProvider";
import AppRouterProvider from "app/core/providers/AppRouterProvider";
import AppScrollbarWidthProvider from "app/core/providers/AppScrollbarWidthProvider";
import AppTenantDetectionProvider from "app/core/providers/AppTenantDetectionProvider";
import AppTenantUserLinkProvider, { useAppTenantUserLinkContext } from "app/core/providers/AppTenantUserLinkProvider";
import AppTopNavProvider from "app/core/providers/AppTopNavProvider";
import AppUpdateProvider from "app/core/providers/AppUpdateProvider";
import AppUserProvider, { useAppUserContext } from "app/core/providers/AppUserProvider";
import AppUserTenantsProvider from "app/core/providers/AppUserTenantsProvider";
import AppToasts from "app/features/appMessages/components/AppToasts";
import FirebaseAuthProvider from "app/features/auth/components/FirebaseAuthProvider";
import SnapshotProvider from "app/features/snapshots/components/SnapshotProvider";
import SnapshotScopeProvider from "app/features/snapshots/components/SnapshotScopeProvider";
import WebSocketProvider from "app/features/webSockets/WebSocketProvider";
import AppLayout from "./AppLayout";

const appCommonProviders = [
  AppUpdateProvider,
  AppRouterProvider,
  AppIntlProvider,
  AppNowProvider,
  AppScrollbarWidthProvider,
  AppTopNavProvider,

  AppTenantDetectionProvider,
  AppUserProvider,
  AppUserTenantsProvider,
  AppTenantUserLinkProvider,

  FirebaseAuthProvider,
  WebSocketProvider,
];

const useAppSnapshots = () => {
  const { userHasTenant } = useAppUserContext();
  const { isTenantUserPlanner } = useAppTenantUserLinkContext();
  const isDebugTenant = useIsDebugTenant();
  // TODO also use this in AppTopNavbar.tsx for example!
  const provideSnapshots = !isDebugTenant && userHasTenant && isTenantUserPlanner;
  return provideSnapshots;
};

const useAppTenantProviders = () => {
  const provideSnapshots = useAppSnapshots();

  return useMemo(
    () => [provideSnapshots && SnapshotScopeProvider, provideSnapshots && SnapshotProvider].filter(Boolean),
    [provideSnapshots],
  );
};

interface ProviderComponent {
  // Each provider is a component that takes `children` as a prop.
  (props: { children: ReactNode }): JSX.Element;
}

interface ProvidersProps {
  providers: ProviderComponent[];
  children: ReactNode;
}

const Providers = ({ providers, children }: ProvidersProps) => {
  // Reversed list of Providers so the first in the array becomes the first parent of all following Providers.
  // Wrap each provider with the next one.
  return [...providers].reverse().reduce((acc, Provider) => <Provider>{acc}</Provider>, children);
};

interface AppCommonProvidersProps {
  children: ReactNode;
}

interface AppTenantProvidersProps {
  children: ReactNode;
}

const AppCommonProviders = ({ children }: AppCommonProvidersProps) => (
  <Providers providers={appCommonProviders}>{children}</Providers>
);
const AppTenantProviders = ({ children }: AppTenantProvidersProps) => (
  // @ts-expect-error TODO fix types for useAppTenantProviders
  <Providers providers={useAppTenantProviders()}>{children}</Providers>
);

//
// Root component that is rendered to the page
//

interface AppProps {
  store: ReduxStore;
}

const App = ({ store }: AppProps) => (
  <AppErrorBoundary store={store}>
    <ReduxProvider store={store}>
      <AppCommonProviders>
        <AppTenantProviders>
          <AppLayout />
          <AppToasts />
          <AppUpdateAvailableModal />
        </AppTenantProviders>
      </AppCommonProviders>
    </ReduxProvider>
  </AppErrorBoundary>
);

export default memo(App);
