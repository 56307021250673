export const manageChangeoversUrl = "manage/changeovers";

export const manageResourceStatesUrl = "manage/resource-states";
export const getResourceStateDetailsUrl = (pk: number | string) => `${manageResourceStatesUrl}/${pk}`;
export const getResourceStateEditUrl = (pk: number | string) => `${manageResourceStatesUrl}/${pk}/edit`;
export const newResourceStateUrl = `${manageResourceStatesUrl}/new`;

export const manageResourceStateMatricesUrl = "manage/resource-state-matrices";
export const getResourceStateTransitionMatrixDetailsUrl = (pk: number | string) =>
  `${manageResourceStateMatricesUrl}/${pk}`;
export const getResourceStateTransitionMatrixEditUrl = (pk: number | string) =>
  `${manageResourceStateMatricesUrl}/${pk}/edit`;
export const newResourceStateMatrixUrl = `${manageResourceStateMatricesUrl}/new`;

// Matrix resets
export const getResourceStateTransitionMatrixDetailsResetsUrl = (pk: number | string) =>
  `${manageResourceStateMatricesUrl}/${pk}/resets`;
export const getResourceStateTransitionMatrixNewResetUrl = (pk: number | string) =>
  `${manageResourceStateMatricesUrl}/${pk}/resets/new`;
export const getResourceStateTransitionMatrixEditResetUrl = (matrixPk: number | string, resetPk: number | string) =>
  `${manageResourceStateMatricesUrl}/${matrixPk}/resets/${resetPk}/edit`;

// Matrix transitions
export const getResourceStateTransitionMatrixDetailsTransitionsUrl = (pk: number | string) =>
  `${manageResourceStateMatricesUrl}/${pk}/transitions`;
export const getResourceStateTransitionMatrixNewTransitionUrl = (pk: number | string) =>
  `${manageResourceStateMatricesUrl}/${pk}/transitions/new`;
export const getResourceStateTransitionMatrixEditTransitionUrl = (
  matrixPk: number | string,
  transitionPk: number | string,
) => `${manageResourceStateMatricesUrl}/${matrixPk}/transitions/${transitionPk}/edit`;

export const importResourceStatesUrl = "manage/resource-states/import";
export const importResourceStateTransitionsUrl = "manage/resource-states/import-transitions";
